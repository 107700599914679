.formInput{
    display: flex;
    flex-direction: column;
    width: 300px;
}
label{
    font-size: 14px;
    color: gray;
    font-weight: 200;
}
input{
    padding: 15px;
    margin: 10px 0px;
    border-radius: 5px;
    border: 1px solid gray;
}
.formInput span{
    font-weight: 200;
    font-size: 12px;
    color: red;
    padding: 3px;
    display: none;
}
input:invalid[focused="true"]{
    border: 1px solid red;
}
input:invalid[focused="true"] ~ span{
    display: block;
}