* {
    margin: 0;
    padding: 0;
}

.form-page-container {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 80px 100px;
    z-index: 1;
    flex-direction: column;
    align-items: center;
}
form{
    background-color: transparent;
    padding: 0px 20px;
    color: black;
    border-radius: 10px;
    border: 0.1px solid #2b2765;
    max-height: 60vh;
    overflow: scroll;
    backdrop-filter: blur(3px);
}
h1{
    color: black;
    text-align: center;
}
.form-footer{
    display: flex;
    flex-direction: column;
}
button{
    width: 100%;
    height: 50px;
    padding: 10px;
    border: none;
    background-color: #2b2765;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 30px;
}