.battlegnd-page-container{
    display: flex;
    justify-content: center;
    position: relative;
    padding: 80px 100px;
    z-index: 1;
    flex-direction: column;
    align-items: center;
}
.bg-heading{
    font-size: 42px;
    text-align: center;
    font-weight: 600;
    margin-top: 20px;
}
.bg-description{
    font-size: 22px;
    width: 100%;
    border: 0.1px solid #2b2765;border: 0.1px solid #2b2765;
    box-shadow: rgba(54, 137, 148, 0.15) 0px 4px 24px;
    border-radius: 8px;
    padding: 5px;
    margin: 5px;
    margin-left: 0;
    gap: 5px;
}
.bg-description span{
    font-size: 30px;
}
.battlegnd-page-container img{
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 820px) {
    .battlegnd-page-container{
        padding: 32px 16px;
    }
}
