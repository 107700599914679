.contact-container {
  max-width: 600px;
  min-height: calc(100vh - 2rem);
  margin: 50px auto 0.5rem auto;
  padding: 1rem;
  background-color: transparent;
  border-radius: 8px;
  backdrop-filter: blur(3px);
}

.contact-header {
  text-align: center;
  margin-bottom: 0.75rem;
  color: black;
}

.contact-header h1 {
  font-size: 1.75rem;
  margin-bottom: 0.25rem;
}

.contact-header p {
  font-size: 0.9rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

.form-group label {
  font-size: 0.9rem;
  font-weight: 600;
  color: #666666;  /* Changed from black to grey */
  margin-bottom: 0.05rem;
}

.form-group input {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  height: 45px;
  outline: none;
  color: #666666;
}

.form-group textarea {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  min-height: 120px;
  resize: vertical;
  height: auto;
  outline: none;
  color: black;
}

.submit-button {
  background-color: #2b2765;
  width: 100%;
  height: 45px;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 10px;
  transition: opacity 0.3s;
}

.submit-button:hover {
  opacity: 0.9;
}

.feedback {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  text-align: center;
  font-size: 0.9rem;
}

.feedback.success {
  background-color: rgba(212, 237, 218, 0.9);
  border-color: #2b2765;
  color: #155724;
}

.feedback.error {
  background-color: rgba(248, 215, 218, 0.9);
  border-color: #2b2765;
  color: #721c24;
}

.submit-button.loading {
  background-color: #6c757d;
  cursor: not-allowed;
  opacity: 0.7;
}

.submit-button:disabled {
  cursor: not-allowed;
}
