* {
    margin: 0;
    padding: 0;
}

.market-page {
    max-width: 700px;
    margin: 100px auto;
}


.marketplace-container{
    margin: auto;
    background-color: yellowgreen;
    align-items: center;
    border-radius: 20px;
    margin: 150px 1rem;
    padding: 20px;

}

.heading {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    box-shadow: 0px 0px 12px coral;
    border-radius: 8px;
    margin: 2rem;
    padding: 1.2rem;
}

.resources{
    display: flex;
    height: 200px;
    justify-content: space-between;
    margin: 1.5rem 0.5rem;
    border-radius: 8px;
}

.resources-container a {
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
}

.resources a {
    background-color: blue;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    margin: 5px 10px;
}

.resources a:hover {
    background-color: aqua;
}
