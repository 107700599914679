* {
  margin: 0;
  padding: 0;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  border-bottom: 2px solid #895731;
  color: #ddd;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 7;
}

.icon {
  width: 200px;
  height: 90px;
  margin-top: 5px;
  margin-left: 15px;
  backdrop-filter: blur(3px);
}

.icon:hover {
  color: aqua;
}

.avatar-icon {
  font-size: 3rem; /* Adjust the size as needed */
}

.links-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  backdrop-filter: blur(3px);
}

.navbar a {
  height: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

.navbar a:hover{
  color: aqua;
}

.navbar .active {
  color: blue;
  margin-right: auto;
}

#sidebar-active {
  display: none;
}

.open-sidebar-btn, .close-sidebar-btn{
  cursor: pointer;
  color: white;
  display: none;
}

.navbar .action {
  border-radius: 30px;
  width: 100px;
  height: 50px;
  margin: 0 40px;
  justify-content: center;
  background: -webkit-linear-gradient(
    225deg,
    #895731 0%,
    #347e35 100%
  );
  box-shadow: 5px 5px 70px #1f2634, -5px -5px 90px #1f2634;
  border-radius: 50px;
  font-weight: 600;
  font-size: 20px;
}

.action:hover {
  transform: scale(1.05);
  transition: all 0.4s ease-in-out;
}

@media screen and (max-width: 820px) {
  .navbar{
    background-color: #1f2634;
  }
  .links-container{
    background-color: #353353;
    color: white;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    right: -90%;
    z-index: 10;
    width: 260px;
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.25);
    transition: 0.75s ease-out;
  }
  .navbar a {
    box-sizing: border-box;
    height: auto;
    width: 100%;
    padding: 20px 30px;
    justify-content: flex-start;
    color: white;
  }
  .open-sidebar-btn, .close-sidebar-btn {
    padding: 20px;
    display: block;
  }
  #sidebar-active:checked ~ .links-container {
    right: 0;
  }
  #sidebar-active:checked ~ #overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }
  .navbar .action {
    width: 100px;
    height: 50px;
    margin: 30px 20px;
    align-content: center;
  }
  .icon {
    margin-right: 170px;
    width: 150px;
    height: 50px;
  }
}

