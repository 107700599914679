.about-page-container{
    display: flex;
    justify-content: center;
    position: relative;
    padding: 80px 100px;
    z-index: 1;
    flex-direction: column;
    align-items: center;
}

.writing-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 0.1px solid #2b2765;border: 0.1px solid #895731;
    box-shadow: rgba(54, 137, 148, 0.15) 0px 4px 24px;
    border-radius: 8px;
    border-width: 3px;
}
.subheadings{
    font-size: 42px;
    text-align: center;
    font-weight: 600;
    margin-top: 20px;
}
.vynechain-description{
    font-size: 22px;
    border: 0.1px solid #2b2765;border: 0.1px solid #2b2765;
    box-shadow: rgba(54, 137, 148, 0.15) 0px 4px 24px;
    border-radius: 8px;
    padding: 5px;
    margin: 5px;
}
.solution{
    font-size: 20px;
    border: 0.1px solid #2b2765;border: 0.1px solid #30ada9;
    box-shadow: rgba(54, 137, 148, 0.15) 0px 4px 24px;
    border-radius: 8px;
    padding: 5px;
    margin: 5px;
    margin-bottom: 10px;
}

.assets-container{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1100px;
    gap: 12px;
}
.assets-title{
    font-size: 42px;
    text-align: center;
    font-weight: 600;
    margin-top: 20px;
}
.assets-desc{
    font-size: 18px;
    text-align: center;
    max-width: 600px;
}

.asset-container{
    width: 100%;
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 15px;
    margin: 0 auto;
    color: black;
}
.asset-container .asset-card{
    position: relative;
    width: 300px;
    height: 400px;
    margin: 0 auto;
    transition: 0.5s;
    border-radius: 10px;
}
.asset-container .asset-card:hover{
    box-shadow: 0 30px 50px rgba(0, 0, 0, 0.2);
}
.asset-container .asset-card .face{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.asset-container .asset-card .face.face1{
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
    background: #fff;
    color: black;
}
.asset-container .asset-card .face.face1 h2{
    margin-top: 60px;
}
.asset-container .asset-card .face.face2{
    transition: 0.5s;
}
.asset-container .asset-card:hover .face.face2{
    height: 150px;
    width: 150px;
    border-radius: 50%;
    top: 40px;
    left: 50%;
    transform: translate(-50%);
    background: transparent;
    border: 3px solid #30ada9;
}
.asset-container .asset-card .face.face2 img{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    border-radius: 10px;
}
.asset-container .asset-card:hover .face.face2 img{
    border-radius: 50%;
}
@media screen and (max-width: 820px) {
    .about-page-container{
        padding: 32px 16px;
    }
    .asset-container .asset-card{
        width: 150px;
        height: 200px;
    }
    .asset-container .asset-card:hover .face.face2{
        height: 70px;
        width: 70px;
        margin-top: -30px;
    }
}