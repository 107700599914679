.farming-page-container{
    display: flex;
    justify-content: center;
    position: relative;
    padding: 80px 100px;
    z-index: 1;
    flex-direction: column;
    align-items: center;
}
.farming-page-container img{
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 820px) {
    .farming-page-container{
        padding: 32px 16px;
    }
}