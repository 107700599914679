.beta-testing-page-container{
    padding: 80px 100px;
    z-index: 1;
}
.beta-testing-page-heading{
    font-size: 32px;
    text-align: center;
    font-weight: 600;
    margin-top: 20px;
}
.beta-testing-subheading{
    font-size: 28px;
    margin-top: 20px;
}
.beta-testing-description{
    font-size: 20px;
    width: 100%;
    border: 0.1px solid #2b2765;
    box-shadow: rgba(54, 137, 148, 0.15) 0px 4px 24px;
    border-radius: 8px;
    padding: 5px;
    margin: 5px;
    margin-left: 0;
    gap: 5px;
}
.beta-testing-description-child{
    margin-top: 10px;
}
@media screen and (max-width: 820px) {
    .beta-testing-page-container{
        padding: 32px 16px;
    }
}