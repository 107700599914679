* {
    margin: 0;
    padding: 0;
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.modal-container {
    width: 600px;
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    color: black;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 15px;
}

.order-modal {
    width: 100%;
}

.order-info {
    background: #39b7d6;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.close-btn {
    position: fixed;
    top: 8px;
    right: 8px;
    color: black;
}

.content {
    padding: 10px 10px;
}

h1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.price-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.quantity-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.balance-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.amount-container {
    display: flex;
    align-items: center;
    padding: 10px 20px;
}

.amount-container input {
    align-items: center;
    justify-content: center;
    width: 60%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
}

.amount-container button {
    color: #ffffff;
    padding: 15px 30px;
    margin: auto 10px;
    border-radius: 10px;
    border: none;
    background: -webkit-linear-gradient(
        225deg,
        #895731 0%,
        #39b7d6 100%
        );
}

.btn-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0;
}


.cancel-btn {
    padding: 15px 30px;
    margin: auto 10px;
    border-radius: 10px;
    border: none;
    color: #ffffff;
    background: #db2d27;
    cursor: pointer;
}

.buy-btn {
    padding: 15px 50px;
    margin: auto 10px;
    border-radius: 10px;
    border: none;
    background-color: #1ecb3b;
    cursor: pointer;
}

@media screen and (max-width: 820px) {
    .modal-container {
        width: 400px;
        align-items: center;
        align-content: center;
        flex-direction: column;
        top: 50%;
        margin: 0 auto;
    }
}