.resource-container {
    max-width: 1200px;
    margin: 150px auto;
}

.resource-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #8e6530;
    box-shadow: 0px 0px 12px #18191b;
    border-radius: 12px;
    margin: 2rem 1rem;
    padding: 1rem 1rem;
    font-weight: bold;
}

.wood-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3b8183;
    box-shadow: 0px 0px 10px #9e723a;
    border-radius: 12px;
    margin: 1rem 1rem;
    padding: 0.5rem 1rem;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
}

.wood-row:hover {
    transform: scale(1.04);
    transition: .3s ease-in-out;
}