.loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader{
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    height: 50px;
    animation: spin 2s linear;
}

@keyframes spin {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}