.profile-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

.profile-header {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 10px;
}

.profile-info {
  padding: 20px 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.profile-info h1 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.profile-info p {
  margin: 5px 0;
  color: #666;
}

.profile-info .bio {
  margin-top: 10px;
  font-style: italic;
}

.profile-buttons-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.edit-profile-btn {
  margin-top: 5px;
  width: 60%;
  padding: 5px 5px;
  background: -webkit-linear-gradient(
    225deg,
    #895731 0%,
    #347e35 100%
    );
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 12px;
}

.edit-profile-btn:hover {
  background-color: #5a75d1;
}

.delete-profile-btn {
  margin-top: 5px;
  width: 60%;
  padding: 5px 5px;
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 12px;
}

.delete-profile-btn:hover {
  background-color: #e60000;
}

.profile-stats {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.stat-item {
  text-align: center;
  flex: 1;
  margin: 10px;
}

.stat-value {
  display: block;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.stat-label {
  color: #666;
  font-size: 12px;
}

.profile-content {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.profile-section h2 {
  margin-top: 0;
  color: #333;
  font-size: 20px;
}

.activity-list {
  margin-top: 15px;
}

.activity-item {
  padding: 15px 0;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activity-item:last-child {
  border-bottom: none;
}

.activity-item p {
  margin: 0;
  color: #333;
}

.activity-item span {
  color: #999;
  font-size: 14px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(233, 233, 233, 0.1);
}

.modal-content h2 {
  margin-top: 0;
  color: #333;
}

.modal-content p {
  color: #666;
}

.modal-content input {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.confirm-delete-btn {
  padding: 5px 5px;
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 12px;
}

.confirm-delete-btn:hover {
  background-color: #e60000;
}

.cancel-delete-btn {
  padding: 5px 5px;
  background-color: #ccc;
  color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 12px;
}

.cancel-delete-btn:hover {
  background-color: #999;
}

@media screen and (max-width: 600px) {
  .profile-stats {
    padding: 10px;
  }

  .stat-item {
    margin: 5px;
  }

  .stat-value {
    font-size: 18px;
  }

  .stat-label {
    font-size: 10px;
  }
}
