.footer-container{
    background-color: #1F2634;
}
.section-padding{
    padding: 4rem 4rem;
}
.footer{
    display: flex;
    flex-direction: column;
}
.footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}
.footer-links-div{
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;
}
a{
    color: rgb(175, 175, 179);
    text-decoration: none;
}
.socialmedia{
    display: flex;
}
.socialmedia img{
    width: 80%;
}
.footer-links-div h4{
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;
}
.footer-links-div p{
    font-size: 17px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;
}
.footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}
.footer-below-links{
    display: flex;
    flex-direction: row;
}
.footer-below-links p{
    font-size: 13px;
    line-height: 15px;
    margin-left: 2rem;
    color: white;
    font-weight: 600;
}
hr{
    color: white !important;
    width: 100%;
}
.footer-copyright p{
    font-size: 13px;
    line-height: 15px;
    color: rgb(255, 255, 255);
    font-weight: 600;
}

@media screen and (max-width: 820px) {
    .section-padding{
        padding: 1rem 30px;
    }
    .footer-links div{
        margin: 0.5rem 0;
    }
    .footer-below-links{
        flex-direction: row;
    }
    .footer-below-links a{
        margin-bottom: 10px;
    }
    .footer-below-links p{
        margin-left: 10px;
    }
}