.leaderboard-page-container{
    display: flex;
    justify-content: center;
    position: relative;
    padding: 80px 100px;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    max-width: 1400px;
}
.leaderboard-page-container img{
    width: 100%;
    height: 100%;
}
.leaderboard-heading{
    font-size: 42px;
    text-align: center;
    font-weight: 600;
    margin-top: 20px;
}
.leaderboard-subheading{
    font-size: 38px;
    text-align: center;
    font-weight: 600;
    margin-top: 20px;
}
.leaderboard-description{
    font-size: 22px;
    width: 100%;
    border: 0.1px solid #2b2765;border: 0.1px solid #2b2765;
    box-shadow: rgba(54, 137, 148, 0.15) 0px 4px 24px;
    border-radius: 8px;
    padding: 10px;
    margin: 5px;
    margin-left: 0;
    margin-top: 15px;
    gap: 20px;
}
.leaderboard-description span{
    font-size: 30px;
}
.leaderboard-act-description{
    font-size: 22px;
    width: 100%;
    border: 0.1px solid #2b2765;border: 0.1px solid #2b2765;
    box-shadow: rgba(54, 137, 148, 0.15) 0px 4px 24px;
    border-radius: 8px;
    padding: 10px;
    margin: 15px;
}

.leaderboard-act-description p{
    font-size: 30px;
}
.critical{
    color: red;
    padding: 5px;
}

@media screen and (max-width: 820px) {
    .leaderboard-page-container{
        padding: 32px 16px;
    }
}