.home-container{
    display: flex;
    justify-content: center;
    position: relative;
    padding: 80px 100px;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    max-width: 1400px;
}

.home-container img{
    width: 100%;
    height: 100%;
}

.home-inner-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.home-left-container {
    width: 100%;
    order: 1;
}

.home-right-container {
    width: 100%;
    order: 2;
    display: flex;
    justify-content: end;
}

.title {
    font-weight: 700;
    font-size: 50px;
    color: aqua;
    line-height: 68px;
}

.description {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 42px;
}

.learn-more {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    text-decoration: none;
    width: 95%;
    max-width: 300px;
    text-align: center;
    padding: 16px 0;
    background: -webkit-linear-gradient(
    225deg,
    #895731 0%,
    #347e35 100%
    );
    box-shadow: 10px 10px 60px #1f2634, -10px -10px 60px #1f2634;
    border-radius: 50px;
    font-weight: 600;
    font-size: 20px;
    color: white;
}

.learn-more:hover {
    transform: scale(1.05);
    transition: all 0.4s ease-in-out;
    box-shadow:  20px 20px 60px #1F2634,
}

.betatesting-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about-img {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 600px;
}

.vyneapp-container{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 12px;
}
.vyneapp-title{
    font-size: 42px;
    text-align: center;
    font-weight: 600;
    margin-top: 20px;
}
.vyneapp-desc{
    font-size: 18px;
    text-align: center;
    max-width: 600px;
}
.learn{
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.8s ease-in-out;
    background: -webkit-linear-gradient(
    225deg,
    #895731 0%,
    #347e35 100%
    );
    color: white;
    box-shadow: 3px 3px 5px #1f2634, -3px -3px 5px #1f2634;
    margin-top: 10px;
}

.vyneapp-card-container{
    width: 100%;
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 10px;
    margin: 5px auto;
    color: black;
    flex-wrap: wrap;
    border: 3px solid #283a5f;
    border-radius: 10px;
    padding: 5px;
    background-color: #21252f;
}
.vyneapp-container .vyneapp-card{
    position: relative;
    width: 200px;
    height: 250px;
    margin: 5px auto;
    transition: 0.5s;
    border-radius: 10px;
}
.vyneapp-content{
    display: flex;
    flex-direction: column;
}
.vyneapp-container .vyneapp-card:hover{
    box-shadow: 0 30px 50px rgba(0, 0, 0, 0.2);
}
.vyneapp-container .vyneapp-card .face{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.vyneapp-container .vyneapp-card .face.face1{
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
    background: #fff;
    color: black;
}
.vyneapp-container .vyneapp-card .face.face1 h2{
    margin-top: 60px;
}
.vyneapp-container .vyneapp-card .face.face2{
    transition: 0.5s;
}
.vyneapp-container .vyneapp-card:hover .face.face2{
    height: 70px;
    width: 70px;
    border-radius: 50%;
    top: 10px;
    left: 50%;
    transform: translate(-50%);
    background: transparent;
    border: 3px solid #30ada9;
}
.vyneapp-container .vyneapp-card .face.face2 img{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    border-radius: 10px;
    background: #283a5f;
}
.vyneapp-container .vyneapp-card:hover .face.face2 img{
    border-radius: 50%;
}
.tokenomics-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.tokenomics-container img{
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 20px;
}
.tokenomics-desc-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 0.1px solid #2b2765;
    box-shadow: rgba(54, 137, 148, 0.15) 0px 4px 24px;
    border-radius: 8px;
    padding: 5px;
    margin: 10px;
}
.tokenomics-desc-container span{
    font-size: 30px;
    margin: 5px;
}
.tokenomics-desc{
    margin: 5px;
    padding: 5px;
    border: 0.1px solid #2b2765;
    border-radius: 8px;
}
.tokenomics-title{
    font-size: 42px;
    text-align: center;
    font-weight: 600;
    margin-top: 20px;
}
.aloc-cards{
    display: flex;
    flex-wrap: wrap;
}
.aloc-card{
    background-color: #30ada9;
    padding: 15px;
    margin: 5px;
    border: 3px solid #283a5f;
    border-radius: 20px;
}
.roadmap{
    position: relative;
    margin: 10px auto;
    width: 100%;
}
.roadmap-container{
    padding: 10px 50px;
    position: relative;
    width: 50%;
}
.roadmap-container img{
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    right: -20px;
    top: 32px;
    z-index: 10;
    background-color: #fff;
    background-position: center;
    border: 3px solid #30ada9;
}
.right-container img{
    left: -20px;
}
.text-box{
    padding: 20px 30px;
    background: #1F2634;
    border: 3px solid #30ada9;
    border-radius: 10px;
    position: relative;
}
.text-box small{
    display: inline-block;
    margin-bottom: 15px;
}
.period{
    color: #347e35;
    font-weight: bold;
    letter-spacing: .1rem;
}
.left-container{
    left: 0;
}
.right-container{
    left: 50%;
}
.roadmap::after{
    content: '';
    position: absolute;
    width: 6px;
    height: 100%;
    background: #895731;
    top: 0;
    left: 50%;
    margin-left: -3px;
    z-index: -1;
}
.left-container-arrow{
    height: 0;
    width: 100px;
    position: absolute;
    top: 38px;
    z-index: -1;
    right: -15px;
    border: 5px solid #30ada9;
}
.right-container-arrow{
    height: 0;
    width: 100px;
    position: absolute;
    top: 38px;
    z-index: -1;
    left: -15px;
    border: 5px solid #30ada9;
}

/* App Download Section */
.app-download-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #3892a8; /* Adjust background color as needed */
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  
.app-download-title {
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
  }
  
.app-download-buttons {
    display: flex;
    gap: 20px;
  }
  
.app-button {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    text-decoration: none;
    transition: background-color 0.3s ease;
    font-weight: bold;
  }
  
.vyneapp-button {
    background-color: #3ddc84; /* Green for VYNE App */
  }
  
.battleground-button {
    background-color: #007aff; /* Blue for Battleground App */
  }
  
.app-button:hover {
    opacity: 0.8;
  }
  

@media screen and (max-width: 820px) {
    .home-container {
        padding: 32px 16px;
    }
    .home-inner-container {
        flex-direction: column;
    }
    .home-left-container {
        order: 2;
        margin-bottom: 30px;
        display: flex;
        gap: 6px;
        flex-direction: column;
        align-items: center;
    }
    .home-right-container {
        order: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 80px;
        margin-bottom: 30px
    }
    .title {
        text-align: center;
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 8px;
    }
    .description {
        text-align: center;
        font-size: 16px;
        line-height: 32px;
    }
    .learn-more {
        padding: 12px 0;
        font-size: 18px;
    }
    .about-img {
        max-width: 300px;
        max-height: 300px;
    }
    .assets-contaner{
        flex-direction: column;
    }
    .asset{
        width: 100%;
        max-width: 150px;
    }
    .asset-image-container{
        width: 110px;
        height: 80px;
    }
    .vyneapp-card-container .vyneapp-card{
        width: 150px;
        height: 200px;
    }
    .vyneapp-content{
        height: 250px;
        margin-top: 30px;
    }
    .vyneapp-content p{
        height: 30%;
        font-size: 12px;
    }
    .learn {
        height: 35px;
        padding: 1px;
        margin-top: 0px;
    }
    .aloc-card{
        padding: 5px;
    }
    .vyneapp-container .vyneapp-card:hover .face.face2{
        height: 45px;
        width: 45px;
        margin-top: -5px;
    }
    .tokenomics-container img{
        width: 350px;
        height: 250px;
    }
    .roadmap {
        margin: 50px auto;
    }
    .roadmap.roadmap::after{
        left: 31px;
    }
    .roadmap-container{
        width: 100%;
        padding-left: 80px;
        padding-right: 25px;
    }
    .text-box{
        font-size: 13px;
    }
    .text-box small{
        margin-bottom: 10px;
    }
    .right-container{
        left: 0;
    }
    .right-container img , .left-container img {
        left: 10px;
    }
    .left-container-arrow, .right-container-arrow {
        left: -15px;
    }

}