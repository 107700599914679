* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  color: white;
  font-weight: 800;
  background: #3b465e;
  background-size: cover;
  background-position: center;
}

a {
  text-decoration: none;
  color: inherit;
}

.bg{
  margin: 100px auto;
  width: 100%;
}

.bubbles{
  z-index: 2;
  position: fixed;
  display: flex;
}

.bubbles span{
  position: relative;
  min-width: 30px;
  height: 30px;
  background-image: url('./Assets/c7__091145.png');
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: 0 0 10px #2733ba64,
  0 0 50px #4756e6,
  0 0 100px #4756e6;
  margin: 0 4px;
  animation: animate 15s linear infinite;
  animation-duration: calc(77s / var(--i));
}

.bubbles span:nth-child(even) {
  background-image: url('./Assets/c2_3_103011.png');
  background-position: center;
  background-size: cover;
  box-shadow: 0 0 10px #02bd3791,
  0 0 50px #28e451,
  0 0 100px #28e451;
}

@keyframes animate {
  0%
  {
    transform: translateY(-10vh) scale(0);
  }
  100%
  {
    transform: translateY(90vh) scale(1);
  }
}
